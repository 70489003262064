import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import Box from "@material-ui/core/Box"
import { contentPaddingY } from "../config"
import Gallery from "../components/gallery"

const GalleryPage = () => {
  const data = useStaticQuery(graphql`
    query {
      bgImage: file(relativePath: { eq: "roof-5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout title="Gallery" bgImage={data.bgImage.childImageSharp.fluid}>
      <SEO title="Gallery" />

      <Box
        py={contentPaddingY}
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Gallery/>
      </Box>
    </Layout>
  )
}

export default GalleryPage
